// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-checkout-tsx": () => import("./../../../src/components/Checkout.tsx" /* webpackChunkName: "component---src-components-checkout-tsx" */),
  "component---src-components-my-account-tsx": () => import("./../../../src/components/MyAccount.tsx" /* webpackChunkName: "component---src-components-my-account-tsx" */),
  "component---src-components-page-tsx": () => import("./../../../src/components/Page.tsx" /* webpackChunkName: "component---src-components-page-tsx" */),
  "component---src-components-subscription-gift-tsx": () => import("./../../../src/components/SubscriptionGift.tsx" /* webpackChunkName: "component---src-components-subscription-gift-tsx" */),
  "component---src-components-subscription-tsx": () => import("./../../../src/components/Subscription.tsx" /* webpackChunkName: "component---src-components-subscription-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

